import Vue from 'vue'
import Vuex from 'vuex'
import index from "@/store/modules/index"
import id from "@/store/modules/id"
Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    index,
    id
  }
})
