import axios from 'axios'


import request from "./request.js"

//post请求接口
export const Post = (url, params) => {
        return new Promise((resolve, reject) => {
            request({
                method: "post",
                url: url,
                data: params || {},
                // headers: {
                //   "Content-Type": "application/json"
                // }
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
    //post请求接口
export const Posts = (url, params) => {
    return new Promise((resolve, reject) => {
        request({
            method: "post",
            url: url,
            data: params || {},
            responseType: 'blob'
                // headers: {
                //   "Content-Type": "application/json"
                // }
        }).then(res => {


            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
export const Get = (url, params) => {
    return new Promise((resolve, reject) => {
        request({
            method: "Get",
            url: url,
            params: params || {},
            // headers: {
            //   "Content-Type": "application/json"
            // }
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// 获取小程序json
export const login = params => {
    return Post(`/api/login`, params)
};
export const is_allow = params => {
    return Post(`/api/is_allow`, params)
};
export const base_upload = params => {
    return Post(`/common/base_upload`, params)
};
export const type = params => {
    return Post(`/common/type`, params)
};
export const award_details = params => {
    return Post(`/common/award_details`, params)
};
export const sub_data = params => {
    return Post(`/api/sub_data`, params)
};
export const sms_send = params => {
    return Post(`/common/sms_send`, params)
};

export const api_sign = params => {
    return Post(`/api/sign`, params)
};
export const forget = params => {
    return Post(`/api/forget`, params)
};
export const menu = params => {
    return Post(`/api/menu`, params)
};
export const group_list = params => {
    return Post(`/api/group_list`, params)
};
export const user_list = params => {
    return Post(`/api/user_list`, params)
};

export const award_list = params => {
    return Post(`/api/award_list`, params)
};
export const create_user = params => {
    return Post(`/api/2022/create_user`, params)
};
export const user_edit = params => {
    return Post(`/api/user_edit`, params)
};
export const user_chile_details = params => {
    return Post(`/api/user_chile_details`, params)
};
export const user_child_details = params => {
    return Post(`/api/user_child_details`, params)
};
export const innovation_will = params => {
    return Post(`/api/innovation_will`, params)
};
export const log_out = params => {
    return Post(`/api/log_out`, params)
};
export const innovation_select = params => {
    return Post(`/common/innovation_select`, params)
};
export const user_details_list = params => {
    return Post(`/api/user_details_list`, params)
};
export const submit_audit = params => {
    return Get(`/api/2022/submit_audit`, params)
};
export const turn_down = params => {
    return Post(`/api/turn_down`, params)
};
export const innovation_unit_will = params => {
    return Post(`/api/innovation_unit_will`, params)
};
export const promotion_unit_will = params => {
    return Post(`/api/promotion_unit_will`, params)
};
export const promotion_will = params => {
    return Post(`/api/promotion_will`, params)
};
export const craftsmanship_spirit = params => {
    return Post(`/api/craftsmanship_spirit`, params)
};
export const innovation_success = params => {
    return Post(`/api/innovation_success`, params)
};
export const split_del = params => {
    return Post(`/api/split_del`, params)
};
export const upload_file = params => {
    return Post(`/common/upload_file`, params, {
        cache: false,
        contentType: false,
        processData: false
    })
};
export const user_forget = params => {
    return Post(`/api/user_forget`, params)
};
export const award_find = params => {
    return Post(`/common/award_find`, params)
};
export const award = params => {
    return Post(`/common/award`, params)
};
export const upload_pdf = params => {
    return Post(`/common/2022/upload_pdf`, params)
};
export const user_file = params => {
    return Post(`/api/2022/user_file`, params)
};
export const file_download = params => {
    return Posts(`/common/2022/file_download`, params, )
};
export const user_num = params => {
    return Post(`/api/2022/user_num`, params, )
};
export const download_file = params => {
    return Get(`/common/2022/download_file`, params, )
};
///common/2022/user_user_export
export const user_user_export = params => {
    return Post(`/common/2022/user_user_export`, params, )
};

///common/2022/user_user_export
export const awardDetails = params => {
    return Post(`/api/2022/award_details`, params, )
};
///common/2022/user_user_export
export const awards = params => {
    return Post(`/api/2022/award`, params, )
};