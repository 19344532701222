<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {};
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "微软雅黑";
  color: #000000 !important;
}
#app {
  width: 100%;
  height: 100%;
}
.el-dialog__body {
  color: #000000 !important;
}
.el-input.is-focus .el-input__inner {
  border: 1px solid red !important;
}
.el-input__inner {
  height: 50px !important;
  color: #000000;
}
.el-input input::-webkit-input-placeholder {
  font-size: 18px !important;
  color: #000000 !important;
}

.el-input__inner:focus {
  border: 1px solid red !important;
}
.v-modal {
  z-index: 0 !important;
}
.el-alert__title {
  font-size: 22px !important;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border: 1px solid red !important;
}
.el-upload--picture-card {
  width: 250px !important;
}
.el-upload-list--picture-card .el-upload-list__item {
  height: auto !important;
}
.el-form-item__label {
  padding: 0 20px 0 0 !important;
}
.el-input__inner {
  color: #000000 !important;
}
input::-webkit-input-placeholder {
  color: #1d1b1b !important;
}
.el-upload-list__item-name {
  font-size: 20px;
  cursor: pointer;
}
.el-icon-close {
  font-size: 20px;
}
.el-checkbox__label {
  font-size: 20px !important;
}
.el-checkbox {
  margin-bottom: 10px !important;
  margin-left: 5px !important;
}
@page {
  margin: 0;
}
@media print {
  .noprint {
    display: none;
  }
}
.shenMain td {
  font-size: 24px !important;
}
.tabls input {
  font-size: 24px !important;
}
.tabls textarea {
  font-size: 24px !important;
}
.el-textarea__inner {
  font-size: 24px !important;
}
.uplo {
  display: flex;
}
.uplo > div {
  margin: 0 30px;
}
#printTest {
  flex-direction: column;
}
.zunshou {
  height: 100%;

  text-indent: 2em;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zzzzzz {
  font-size: 37px;
}
.biaoges {
  overflow: hidden;
  margin-top: 40px;
}
.fengmian {
  width: 1000px;
  height: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 300px;
  align-items: center;
}
.fengmian > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmian > div:nth-child(1) > div {
  font-size: 40px !important;
}
.fengmian > div:nth-child(2) > div {
  display: flex;
  align-items: center;
}
.fengmian > div:nth-child(2) > div > div:nth-child(1) {
  flex: 0 0 240px !important;
  font-size: 22px !important;
}
.fengmian > div:nth-child(2) > div > div {
  font-size: 22px !important;
}
.fengmian > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 24px !important;
  border-bottom: 1px solid #999;
}
</style>
