// 标题
export default {
  state: {
    index: sessionStorage.getItem('num') || 0
  },
  mutations: {
    index(state, index) {
      sessionStorage.setItem('num', index);
      state.index = index
    }
  },
}
