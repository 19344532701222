import Vue from 'vue'
import VueRouter from 'vue-router'
import {
    exist
} from "../axios/api";
Vue.use(VueRouter)

const routes = [
    // 更换  路由 LiyingqiTravelCase名字时候记得 vue.config.js里面的publicPath 也需要换偶
    // 案例一
    {
        path: '/',
        component: () =>
            import ('../views/manage.vue'),
        children: [
            // {
            //     path: '/declare',
            //     component: () =>
            //         import ('../views/shenqing.vue'),
            // },
            // {
            //     path: '/quanxian',
            //     component: () =>
            //         import ('../views/quanxian.vue')
            // },
            {
                path: '/present',
                component: () =>
                    import ('../views/awards.vue'),
            },
            // {
            //   path: '/innovation',
            //   component: () => import('../views/innovation.vue'),
            // },
            // {
            //   path: '/Promotion',
            //   component: () => import('../views/Promotion.vue'),
            // },
            // {
            //   path: '/Promotions',
            //   component: () => import('../views/Promotions.vue'),
            // },
            // {
            //   path: '/achievement',
            //   component: () => import('../views/achievement.vue'),
            // },
            // {
            //   path: '/craftsman',
            //   component: () => import('../views/craftsman.vue'),
            // }
        ]
    },
    {
        path: '/index',
        component: () =>
            import ('../views/index.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/login.vue'),
    },
    {
        path: '/enroll',
        name: 'enroll',
        component: () =>
            import ('../views/enroll.vue'),
    },
    {
        path: '/forget',
        name: 'forget',
        component: () =>
            import ('../views/forget.vue'),
    },
]
const router = new VueRouter({
    mode: '',
    base: '/',
    routes,
})
router.beforeEach((to, from, next) => {
    const token = sessionStorage.getItem('Tokens')
    if (token && to.path != '/login') {
        // 有token 但不是去 login页面 通过
        next(true)
        if (to.path == '/index') {

            next(true)
        } else {
            // next('/index')
            next(true)
        }
    } else if (token && to.path === '/login') {
        // 有token 但是去 login页面 不通过 重定向到首页
        next('/index')
    } else if (!token && to.path !== '/login') {
        // 没有token 但不是去 login页面 不通过（未登录不给进入）
        console.log(to.path);
        if (to.path === '/enroll') {
            next(true)
        } else if (to.path === '/forget') {
            next(true)
        } else {
            next('/login')
        }
    } else {
        // 剩下最后一种 没有token 但是去 login页面 通过
        next()
    }
})

export default router