// 标题
export default {
  state: {
    id: ""
  },
  mutations: {
    id(state, id) {
      state.id = id
    }
  },
}
